import Image from 'next/image';
import { useState } from 'react';
import styled from 'styled-components';

import Modal from '../Modal';
import OfferModalContent from '../ModalContent/OfferModalContent';

import Button from '@/components/UI/Button';
import { boxBorder, textEllipsis } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	${boxBorder()}
	width: 100%;
`;
const Cover = styled.div`
	border-bottom: 1px solid #000;
	height: 45vw;
	position: relative;
	width: 100%;
	@media ${devices.mobile} {
		height: 18vw;
	}
	@media ${devices.laptop} {
		height: 148px;
	}
`;
const CoverImage = styled(Image)`
	height: 45vw;
	object-fit: cover;
	width: 100%;
	@media ${devices.mobile} {
		height: 18vw;
	}
	@media ${devices.laptop} {
		height: 147px;
	}
`;
const CompanyLogo = styled(Image)`
	background-color: ${color.whiteColor};
	bottom: -34px;
	height: 68px;
	object-fit: contain;
	${boxBorder()}
	position: absolute;
	right: 16px;
	width: 68px;
`;

const Content = styled.div`
	background-color: ${color.whiteColor};
	padding: 24px 18px 18px 18px;
`;
const Name = styled.p`
	font-size: 18px;
	font-weight: 600;
	line-height: 18px;
	margin: 0 0;
`;
const Infos = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin: 16px 0;
	width: 100%;
`;
const Info = styled.p`
	font-size: 14px;
	line-height: 14px;
	margin: 0 0;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	${textEllipsis(1)}
`;
const Salary = styled.span`
	color: ${color.primaryColor};
`;
const BtnContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: end;
`;

interface OfferCardProps {
	cover: string;
	company: {
		logo: string;
		name: string;
	};
	university: string;
	program: string;
	job: string;
	salary: string;
	name: string;
	offer: ITestimonialOffer;
}

const OfferCard = ({
	cover,
	company,
	university,
	program,
	job,
	salary,
	name,
	offer
}: OfferCardProps) => {
	const [showOfferModal, setShowOfferModal] = useState<boolean>(false);
	const handleCloseOfferModal = () => {
		setShowOfferModal(false);
	};

	return (
		<Container>
			<Cover>
				<CoverImage
					src={cover || '/image/offer/offer-card-thumbnail--default.webp'}
					alt={company.name}
					unoptimized
					loader={imageLoader}
					width={0}
					height={0}
				/>
				<CompanyLogo
					src={company.logo || '/image/offer/offer-card-logo--default.webp'}
					alt={company.name}
					unoptimized
					loader={imageLoader}
					width={0}
					height={0}
				/>
			</Cover>
			<Content>
				<Name>{name}</Name>
				<Infos>
					<Info>背景&专业: {university}</Info>
					<Info>参与课程: {program}</Info>
					<Info>已入职: {company.name}</Info>
					<Info>职位: {job}</Info>
					<Info>
						年薪: <Salary>{salary}</Salary>
					</Info>
				</Infos>
				<BtnContainer>
					<Button color="white" onClick={() => setShowOfferModal(true)}>
						求职经历
					</Button>
				</BtnContainer>
			</Content>
			<Modal isVisible={showOfferModal} handleClose={handleCloseOfferModal}>
				<OfferModalContent offer={offer} />
			</Modal>
		</Container>
	);
};

export default OfferCard;
