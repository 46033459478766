import Image from 'next/image';
import styled from 'styled-components';

import { boxElevation } from '@/styles/mixin';
import { color, devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const Container = styled.div`
	background-color: ${color.bgColor};
	max-height: 80vh;
	max-width: 786px;
	overflow-y: auto;
	padding: 24px 16px;
	width: 100%;
	@media ${devices.tablet} {
		padding: 24px;
	}
`;
const HeaderContainer = styled.div`
	align-items: center;
	background-color: #fff2e9;
	border: solid 1px #000;
	display: flex;
	gap: 20px;
	justify-content: space-between;
	padding: 16px;
	@media ${devices.tablet} {
		padding: 24px 40px 24px 24px;
	}
	${boxElevation(6, '#fff')}
	width: 100%;
`;
const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: 100%;
	@media ${devices.tablet} {
		width: auto;
	}
`;
const Title = styled.p`
	font-size: 18px;
	font-weight: 600;
	line-height: 1;
	margin: 0;
	margin-bottom: 4px;
`;

const InfoItem = styled.p`
	font-size: 14px;
	line-height: 1;
	margin: 0;
	span {
		color: ${color.primaryColor};
	}
`;
const HeaderImageMobile = styled(Image)`
	border: solid 1px #000;
	display: inherit;
	height: 143px;
	margin-bottom: 4px;
	width: 100%;
	@media ${devices.tablet} {
		display: none;
	}
`;
const HeaderImage = styled(Image)`
	border: solid 1px #000;
	display: none;
	@media ${devices.tablet} {
		display: inherit;
	}
`;
const ContentContainer = styled.div`
	background-color: #fff;
	border: solid 1px #000;
	margin-top: 24px;
	padding: 16px;
	width: 100%;
	@media ${devices.tablet} {
		padding: 24px;
	}
`;
const Section = styled.div`
	border-bottom: dotted 1px #000;
	padding: 24px 0;
	:first-of-type {
		padding-top: 0;
	}
	:last-of-type {
		border-bottom: none;
		padding-bottom: 0;
	}
`;
const SectionTitle = styled.p`
	font-size: 18px;
	font-weight: 600;
	line-height: 1;
	margin: 0;
`;
const Description = styled.p`
	font-size: 14px;
	line-height: 1.43;
	margin: 0;
	margin-top: 16px;
`;
const OfferImage = styled(Image)`
	border: solid 1px #000;
	height: fit-content;
	margin-top: 16px;
	max-width: 460px;
	width: 100%;
`;

const OfferModalContent = ({ offer }: { offer: ITestimonialOffer }) => {
	return (
		<Container>
			<HeaderContainer>
				<InfoContainer>
					<HeaderImageMobile
						src={
							offer.relatedCompany?.backgroundImg?.url ||
							'/image/offer/offer-card-thumbnail--default.webp'
						}
						alt={offer.company || ''}
						unoptimized
						loader={imageLoader}
						width={0}
						height={0}
					/>
					<Title>{offer.name}</Title>
					<InfoItem>
						背景&专业：{offer.university?.chineseName || offer.university?.name}
					</InfoItem>
					<InfoItem>
						参与课程：{(offer.program && offer.program?.name) || '未获取到数据'}
					</InfoItem>
					<InfoItem>已入职：{offer.company}</InfoItem>
					<InfoItem>职位：{offer.jobFunction}</InfoItem>
					<InfoItem>
						年薪：<span>{offer.salary ? `${offer.salary / 1000}k` : '未知'}</span>
					</InfoItem>
				</InfoContainer>
				<HeaderImage
					src={
						offer.relatedCompany?.backgroundImg?.url ||
						'/image/offer/offer-card-thumbnail--default.webp'
					}
					alt={offer.company || ''}
					unoptimized
					loader={imageLoader}
					width={278}
					height={150}
				/>
			</HeaderContainer>
			{(offer.description ||
				offer.description_en ||
				(offer.screenshot && offer.screenshot.url)) && (
				<ContentContainer>
					{(offer.description || offer.description_en) && (
						<Section>
							<SectionTitle>求职经历</SectionTitle>
							<Description>{offer.description || offer.description_en}</Description>
						</Section>
					)}
					{offer.screenshot && offer.screenshot.url && (
						<Section>
							<SectionTitle>学员offer截图</SectionTitle>
							<OfferImage
								src={offer.screenshot.url}
								alt="offer"
								unoptimized
								loader={imageLoader}
								width={0}
								height={0}
							/>
						</Section>
					)}
				</ContentContainer>
			)}
		</Container>
	);
};

export default OfferModalContent;
